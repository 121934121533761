.services{
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 60ch;
    margin-top: 9rem;
    margin-bottom: 13rem;
}
.awesome{
    display: flex;
    flex-direction: column;
    position: relative;
}

.awesome > :nth-child(1){
    color: var(--black);
    font-size: 2.5rem;
    font-weight: bold;
}
.awesome >:nth-child(2){
    color: var(--orange);
    font-size: 2.5rem;
    font-weight: bold;
}
.awesome >:nth-child(3){
    color: var(--gray);
    font-size: 14px;
    margin-top: 1rem;
}
.s-button{
    width: 8rem;
    height: 2rem;
    margin-top: 1rem;
}

.cards{
    position: relative;
}

.cards>*{
    position: relative;
}

.s-blur2{
    left: 14rem;
    top: 8rem;
}

.s-blur{
    top: 13rem;
    left: -18rem;
}

@media screen and (max-width: 768px) {
    .services{
        flex-direction: column;
    }
}